import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertController, ModalController } from '@ionic/angular';
import { TagsComponent } from './tags/tags.component';
import { PhotoDetailComponent } from './photo-detail/photo-detail.component';
import { UsersService } from '../../services/users.service';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ArchiveComponent } from './archive/archive.component';
import { TranslateService } from '@ngx-translate/core';
import { TemplateService } from '../../services/template.service';
import { ToastService } from '../../services/toast.service';
import { GalleryService } from '../../services/gallery.service';
import {
	GalleryEditorModalResult,
	GalleryFileEditorComponent,
} from './gallery-file-editor/gallery-file-editor.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AlbumsComponent } from './albums/albums.component';
import { CategoryTagsComponent } from './category-tags/category-tags.component';
import { MenusService } from '../../services/menus.service';
import { TagService } from '../../services/tag.service';
import { GalleryFileVModel } from '../../models/gallery-file-v.model';
import { GalleryFileFormat, GalleryFileModel } from '../../models/gallery-file.model';
import { Tag, TagCategory } from '../../models/tag-category';
import { IAlbum } from '../../models/IAlbum';
import { CategoriaCliente } from '../../models/categoria-cliente.model';
import { Utility } from '../../helpers/Utility';
import { SelectGroupModalComponent } from '../groups/select-group-modal/select-group-modal.component';
import { GroupFeature } from '../../interfaces/group.interface';
import { IMenu } from '../../models/menu';
import { SelectedTemplatesService } from '../../services/selected-templates.service';
import { GroupsService } from '../../services/groups.service';
import { AddVideoModalComponent } from './add-video-modal/add-video-modal.component';
import * as uuid from 'uuid';

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
	@ViewChild(ArchiveComponent, { static: false }) archiveComponent: ArchiveComponent;
	@Input() isModal = false;
	@Input() isEdit = false;
	@Input() indexSelected = '0';
	@Output() indexSelectedChange = new EventEmitter<string>();
	galleryFiles$: Observable<GalleryFileVModel[]>;
	search = '';
	groupFilter: any | undefined;
	selectedTags: Tag[] = [];
	albums$: Observable<IAlbum[]>;
	albumSelected: IAlbum = null;
	isSede = false;
	categories: CategoriaCliente[];
	showFromSede = true;
	showFromSalone = true;
	showFromWeb = true;
	templates: IMenu[] = [];
	hasGroups = false;
	selectedTemplates: IMenu[] = [];
	public isFiltered = false;
	allFiles: GalleryFileVModel[] = [];
	showFilters = true;
	private counter = 0;
	private allMacroTags: TagCategory[];

	constructor(
		private tagService: TagService,
		private selectedTemplatesService: SelectedTemplatesService,
		private router: Router,
		public usersService: UsersService,
		private alertCtrl: AlertController,
		private translate: TranslateService,
		public templateService: TemplateService,
		private toastCtrl: ToastService,
		private galleryService: GalleryService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private menusService: MenusService,
		private groupsService: GroupsService,
	) {}

	get currentUser() {
		return this.usersService.currentUserInfo;
	}

	get visibleMacroTags() {
		if (this.allMacroTags) {
			return this.allMacroTags.filter((macroTag) => macroTag.IsPrimary);
		} else {
			return [];
		}
	}

	async changeTemplates(templates: IMenu[]) {
		this.selectedTemplatesService.selectedGalleryTemplates$.next([...templates]);
	}

	async ngOnInit() {
		this.tagService.getMacroTags().subscribe((allMacroTags) => {
			this.allMacroTags = allMacroTags;
		});

		if (!this.menusService.currentMenu) {
			this.menusService.currentMenu = await firstValueFrom(this.menusService.getCurrentMenu());
		}

		this.refresh();
		this.albums$ = this.galleryService.getAlbum();
		this.isSede = this.menusService.currentMenu.IsSharingAllowed;

		const readPrivacy = localStorage.getItem('readPrivacy');
		if (!readPrivacy || readPrivacy !== '1') {
			// this.usersService.currentUserInfo.subscribe(user => {
			//   if ([3, 6].includes(user.profiloCliente)) {
			//     // setTimeout(() => {
			//     //   this.modalCtrl.create({
			//     //     component: PrivacyAndPolicyComponent
			//     //   }).then(modal => {
			//     //     modal.present();
			//     //     modal.onDidDismiss().then(result => {
			//     //       if (result.data) {
			//     //         localStorage.setItem('readPrivacy', '1');
			//     //       }
			//     //     });
			//     //   });
			//     // }, 500);
			//   }
			// });
		}

		this.getGalleryFiles().then();

		this.galleryFiles$ = this.galleryService.galleryFiles$;

		this.selectedTemplatesService.availableGalleryTemplates$.subscribe((templates) => {
			this.templates = templates;
		});

		this.selectedTemplatesService.selectedGalleryTemplates$.subscribe((templates) => {
			this.selectedTemplates = templates;

			if (!this.selectedTemplates) return;

			// Filter gallery files by selected templates
			const filteredFiles = this.allFiles.filter((f) => this.selectedTemplates.some((t) => t.Id === f.file.TemplateId));

			this.galleryService.setLocalGalleryFiles(filteredFiles);
		});

		this.groupsService.myGroups$.subscribe((groups) => {
			this.hasGroups = groups.filter((group) => group.Feature === GroupFeature.gallery).length > 0;
		});
	}

	async getGalleryFiles() {
		const galleryFilesQuery$ = this.galleryService.getGalleryFiles(this.groupFilter);

		// Filter by the selected templates
		const selectedTemplates = this.selectedTemplatesService.selectedGalleryTemplates$.value;
		const templateIds = selectedTemplates?.map((t) => t.Id) ?? [];

		const galleryFiles$ = galleryFilesQuery$.pipe(
			map((galleryFiles) => galleryFiles.map((f) => new GalleryFileVModel(f))),
			map((galleryFiles) => galleryFiles.filter((f) => !this.isModal || (f.file.IsFinished && !f.file.IsDeleted))),
			tap((galleryFiles) => {
				this.galleryService.setLocalGalleryFiles(galleryFiles);
			}),
		);

		const files = await lastValueFrom(galleryFiles$);
		this.allFiles = [...files];

		this.galleryService.setLocalGalleryFiles(files.filter((f) => templateIds.includes(f.file.TemplateId)));

		this.selectedTemplatesService.allTemplates$.subscribe((templates) => {
			if (templates.length > 0) {
				this.selectedTemplatesService.filterGalleryTemplates(this.allFiles);
			}
		});
	}

	refresh(): void {
		if (this.archiveComponent) {
			this.archiveComponent.refresh();
		}
	}

	readImage() {
		this.alertCtrl
			.create({
				message: this.translate.instant('questionAddImage'),
				buttons: [
					{
						text: 'Immagine',
						handler: () => {
							this.continueAddImage();
						},
					},
					{
						text: 'Video',
						handler: () => {
							this.alertCtrl
								.create({
									message: this.translate.instant('addVideo'),
									inputs: [
										{
											name: 'link',
											placeholder: 'Link',
										},
									],
									buttons: [
										{
											text: this.translate.instant('dismiss'),
											role: 'dismiss',
										},
										{
											text: this.translate.instant('done'),
											handler: (data) => {
												let url = data.link as string;
												if (url) {
													if (url.includes('youtube') && url.includes('?') && !url.includes('embed')) {
														const params = new URLSearchParams('?' + url.split('?')[1]);
														url = 'https://www.youtube.com/embed/' + params.get('v');
													} else if (url.includes('vimeo.com/') && !url.includes('player.vimeo.com')) {
														url = 'https://player.vimeo.com/video/' + url.split('vimeo.com/')[1];
													}
												}
												this.finalConfigPhoto(
													{
														base64String:
															'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjA76PVpAAAADElEQVQYV2NgYGAAAAAEAAFczf9pAAAAAElFTkSuQmCC',
														format: 'png',
													},
													url,
												);
											},
										},
									],
								})
								.then((alert) => alert.present());
						},
					},
				],
			})
			.then((alert) => alert.present());
	}

	async showAddFileDialog() {
		const alert = await this.alertCtrl.create({
			message: this.translate.instant('questionAddImage'),
			buttons: [
				{
					text: 'Camera',
					handler: () => {
						this.continueAddCameraImage();
					},
				},
				{
					text: 'Immagine',
					handler: () => {
						this.continueAddGalleryImage();
					},
				},
				{
					text: 'Video',
					handler: async () => {
						const alertVideo = await this.modalCtrl.create({
							component: AddVideoModalComponent,
						});
						alertVideo.onDidDismiss().then((result) => {
							if (!result.data) return;

							let url = result.data.videoUrl;
							const file = result.data.videoFile;

							if (url.includes('youtube') && url.includes('?') && !url.includes('embed')) {
								const params = new URLSearchParams('?' + url.split('?')[1]);
								url = 'https://www.youtube.com/embed/' + params.get('v');
							} else if (url.includes('vimeo.com/') && !url.includes('player.vimeo.com')) {
								url = 'https://player.vimeo.com/video/' + url.split('vimeo.com/')[1];
							} else if (url.includes('youtube') && url.includes('/shorts/')) {
								url = url.replace('/shorts/', '/embed/');
							}

							this.continueAddGalleryVideo(url, file);
						});
						await alertVideo.present();
					},
				},
			],
		});

		alert.present();
	}

	onGalleryFileClick(file: GalleryFileVModel) {
		if (this.isModal) {
			this.modalCtrl.dismiss(file.toPhotoModel());
		}
	}

	showTags() {
		this.modalCtrl
			.create({
				component: TagsComponent,
				componentProps: {
					isFiltered: this.isFiltered,
					showFromSede: this.showFromSede,
					showFromSalone: this.showFromSalone,
					showFromWeb: this.showFromWeb,
					selectedTags: this.selectedTags,
					allMacroTags: this.allMacroTags,
					isArchiveTags: false,
				},
				cssClass: 'full-modal',
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((result) => {
					console.log('tags result', result.data);

					if (result.data) {
						this.selectedTags = JSON.parse(JSON.stringify(result.data.tags));
						this.showFromSede = result.data.showFromSede;
						this.showFromSalone = result.data.showFromSalone;
						this.showFromWeb = result.data.showFromWeb;

						if (this.selectedTags.length > 0 || !this.showFromSede || !this.showFromSalone || !this.showFromWeb) {
							this.isFiltered = true;
						}
					}
				});
			});
	}

	public onClearTags() {
		this.selectedTags = [];
		this.isFiltered = false;
		this.showFromSede = true;
		this.showFromSalone = true;
		this.showFromWeb = true;
	}

	close() {
		this.modalCtrl.dismiss(null, 'dismiss');
	}

	openTags() {
		this.modalCtrl
			.create({
				component: CategoryTagsComponent,
				cssClass: ['full-modal'],
			})
			.then((modal) => {
				modal.present();
			});
	}

	openAlbums() {
		this.modalCtrl
			.create({
				component: AlbumsComponent,
				cssClass: ['full-modal'],
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then(() => {
					this.refresh();
				});
			});
	}

	abilitateOperator() {
		if (!this.templateService.showAllOperator) {
			this.counter++;
			if (this.counter > 4) {
				this.templateService.showAllOperator = true;
				this.toastCtrl.presentToast('Funzionalità operatore attivate');
			}
		}
	}

	async onPullRefresh(event: any) {
		await this.getGalleryFiles();
		event.target.complete();
	}

	macroTagsFromSelectedTags() {
		return this.allMacroTags.filter((t) => this.selectedTags.some((st) => st.Id === t.Id));
	}

	showGroupFilters() {
		this.modalCtrl
			.create({
				component: SelectGroupModalComponent,
				componentProps: {
					feature: GroupFeature.gallery,
				},
			})
			.then((modal) => {
				modal.present().then();
				modal.onDidDismiss().then((result) => {
					if (result.data && result.data.length > 0) {
						this.groupFilter = result.data[0];
						this.getGalleryFiles().then();
					} else if (result.data && result.data.length === 0) {
						this.groupFilter = undefined;
						this.getGalleryFiles().then();
					}
				});
			});
	}

	applyFilterTags(tag) {
		if (!this.selectedTags) {
			this.selectedTags = [];
		}

		const tagFiltered = this.selectedTags.find((selectedTag) => selectedTag.Id === tag.Id);
		if (tagFiltered) {
			this.selectedTags = this.selectedTags.filter((selectedTag) => selectedTag.Id !== tag.Id);

			if (this.selectedTags.length === 0) {
				this.isFiltered = false;
			}
		} else {
			this.selectedTags = [...this.selectedTags, JSON.parse(JSON.stringify(tag))];
			this.isFiltered = true;
		}
	}

	getCheck(item: Tag): string {
		return this.selectedTags && this.selectedTags.some((el) => el.Id === item.Id) ? 'primary' : 'secondary';
	}

	private continueAddImage() {
		Camera.getPhoto({
			resultType: CameraResultType.Base64,
			source: CameraSource.Prompt,
			quality: 50,
		}).then((capturedPhoto) => {
			this.finalConfigPhoto(capturedPhoto);
		});
	}

	private continueAddCameraImage() {
		Camera.getPhoto({
			resultType: CameraResultType.Uri,
			source: CameraSource.Camera,
			quality: 50,
		}).then(async (capturedPhoto) => {
			const data = await Utility.scaleToFit(capturedPhoto.webPath, 1920);
			const file: GalleryFileModel = {
				Id: 0,
				ClientId: 0,
				IsFavorite: false,
				IsFinished: false,
				IsFromSede: this.showFromSede,
				IsFromSalone: this.showFromSalone,
				ThumbnailUrl: null,
				Url: null,
				TagCategories: [],
				FileFormat: 'jpeg' as GalleryFileFormat,
				FileBase64: data.split(',')[1],
				RawFileName: uuid.v4() + '.jpg',
				IsDeleted: false,
				ServiceCategoryIds: [],
				ServiceIds: [],
				TemplateId: 0,
				FileType: 0,
				StoreRawFile: false,
			};

			this.addNewGalleryFile(new GalleryFileVModel(file));
		});
	}

	private continueAddGalleryImage() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.multiple = true;

		input.onchange = async (e) => {
			const inputElement = e.target as HTMLInputElement;
			if (inputElement.files) {
				const vModels = await Promise.all(
					Array.from(inputElement.files).map((file) => this.createAndReadFileBlob(file)),
				);
				this.addNewGalleryFiles(vModels);
			}
		};

		input.click();
	}

	private async createAndReadFileBlob(file: File): Promise<GalleryFileVModel> {
		return new Promise((resolve) => {
			const reader = new FileReader();
			let vModel: GalleryFileVModel;

			reader.onload = async (e) => {
				const base64 = e.target?.result as string;
				vModel = new GalleryFileVModel({
					Id: 0,
					ClientId: 0,
					IsFavorite: false,
					IsFinished: false,
					IsFromSede: this.showFromSede,
					IsFromSalone: this.showFromSalone,
					ThumbnailUrl: null,
					Url: null,
					TagCategories: [],
					FileFormat: file.name.split('.').pop() as GalleryFileFormat,
					FileBase64: base64.split(',')[1],
					RawFileName: file.name,
					IsDeleted: false,
					ServiceCategoryIds: [],
					ServiceIds: [],
					TemplateId: 0,
					FileType: 0,
					StoreRawFile: false,
				});
			};

			reader.onloadend = () => {
				resolve(vModel);
			};

			reader.readAsDataURL(file);
		});
	}

	private async videoFileToBase64(videoFile: File): Promise<string> {
		console.log('videoFile', videoFile);

		if (!(videoFile instanceof Blob)) {
			throw new TypeError('The provided value is not a Blob.');
		}

		const reader = new FileReader();
		const promise = new Promise<string>((resolve, reject) => {
			reader.onloadend = () => {
				if (reader.result) {
					resolve(reader.result as string);
				} else {
					reject(new Error('Failed to convert video file to base64.'));
				}
			};
			reader.onerror = () => {
				reject(new Error('Error reading the video file.'));
			};
		});

		reader.readAsDataURL(videoFile);
		return promise;
	}

	private async continueAddGalleryVideo(url: string, videoFile?: File) {
		const file: GalleryFileModel = {
			Id: 0,
			ClientId: 0,
			IsFavorite: false,
			IsFinished: false,
			IsFromSede: this.showFromSede,
			IsFromSalone: this.showFromSalone,
			ThumbnailUrl: null,
			Url: null,
			UrlVideo: url,
			TagCategories: [],
			FileFormat: videoFile.name.split('.').pop() as GalleryFileFormat,
			FileBase64: videoFile ? (await this.videoFileToBase64(videoFile)).split(',')[1] : null,
			RawFileName: videoFile ? videoFile.name : null,
			IsDeleted: false,
			ServiceCategoryIds: [],
			ServiceIds: [],
			TemplateId: 0,
			FileType: 1,
			StoreRawFile: false,
		};

		this.addNewGalleryFile(new GalleryFileVModel(file));
	}

	private addNewGalleryFiles(files: GalleryFileVModel[]) {
		if (files.length === 1) {
			this.addNewGalleryFile(files[0]);
			return;
		}

		const galleryFile = new GalleryFileVModel({
			Id: 0,
			ClientId: 0,
			IsFavorite: false,
			IsFinished: false,
			IsFromSede: this.showFromSede,
			IsFromSalone: this.showFromSalone,
			ThumbnailUrl: null,
			Url: null,
			TagCategories: [],
			FileFormat: null,
			FileBase64: null,
			IsDeleted: false,
			ServiceCategoryIds: [],
			ServiceIds: [],
			TemplateId: 0,
			FileType: 0,
			StoreRawFile: false,
		});

		this.modalCtrl
			.create({
				component: GalleryFileEditorComponent,
				componentProps: {
					galleryFile,
					isSede: false,
					isNew: true,
					isMultiple: true,
					newGalleryFiles: files,
				},
				cssClass: 'full-modal',
			})
			.then(async (modal) => {
				modal.present();
				const newFiles = (await modal.onDidDismiss<GalleryFileVModel[]>()).data;
				this.onCreateNewGalleryFiles(newFiles);
			});
	}

	private addNewGalleryFile(file: GalleryFileVModel) {
		this.modalCtrl
			.create({
				component: GalleryFileEditorComponent,
				componentProps: {
					galleryFile: file,
					isSede: false,
					isNew: true,
				},
				cssClass: 'full-modal',
			})
			.then(async (modal) => {
				await modal.present();
				const res = (await modal.onDidDismiss<GalleryEditorModalResult>()).data;
				if (res?.addedFile) {
					const updatedFiles = [...this.allFiles, res.addedFile];
					this.galleryService.setLocalGalleryFiles(updatedFiles);
					this.allFiles = updatedFiles;

					// Refresh gallery files
					await this.getGalleryFiles();
				}
			});
	}

	private onCreateNewGalleryFiles(files: GalleryFileVModel[]) {
		for (const file of files) {
			file.isProcessing = true;
			lastValueFrom(this.galleryService.createGalleryFile(file.file))
				.then((res) => {
					file.file.Id = res.Id;
					file.file.IsFinished = res.IsFinished;
					file.file.Url = res.Url;
					file.file.ThumbnailUrl = res.ThumbnailUrl;
					file.file.UrlWebp = res.UrlWebp;
					file.file.UrlVideo = res.UrlVideo;
					file.isProcessing = false;
					file.file.State = res.State;
				})
				.finally(() => {
					file.isProcessing = false;

					setTimeout(() => {
						this.getGalleryFiles().then();
					}, 1000);
				});
		}

		this.galleryService.addLocalGalleryFiles(files);
	}

	private finalConfigPhoto(capturedPhoto: Partial<Photo>, urlVideo = '') {
		const newPhotoSrc = 'data:image/' + capturedPhoto.format + ';base64,' + capturedPhoto.base64String;
		// this.compressImage(newPhotoSrc, null, null).then(newPhotoSrc2 => {
		this.modalCtrl
			.create({
				component: PhotoDetailComponent,
				componentProps: { newPhotoSrc, urlVideo },
				cssClass: 'full-modal',
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((resp) => {
					if (this.isModal && resp.data) {
						setTimeout(() => {
							this.modalCtrl.dismiss(resp.data);
						}, 0);
					} else {
						this.refresh();
					}
				});
			});
	}
}
