import { NgModule } from '@angular/core';
import { CheckStorageLinkPipe } from './check-storage-link.pipe';
import { SearchClientiPipe } from './search.pipe';
import { AppNumberPipe } from './number.pipe';
import { SortByPipe } from './sort-by.pipe';
import { SanitizerPipe } from './sanitizer.pipe';
import { FilterServicesPipe } from './filter-services.pipe';
import { CounterCustomerPipe } from './counter-customer.pipe';
import { MatchDisabledPipe } from './match-disabled.pipe';
import { FilterMenuServicesPipe } from './filter-menu-services.pipe';

@NgModule({
	declarations: [
		SearchClientiPipe,
		CheckStorageLinkPipe,
		AppNumberPipe,
		SortByPipe,
		SanitizerPipe,
		FilterServicesPipe,
        FilterMenuServicesPipe,
		FilterServicesPipe,
		CounterCustomerPipe,
		MatchDisabledPipe,
	],
	imports: [],
	exports: [
		SearchClientiPipe,
		CheckStorageLinkPipe,
		AppNumberPipe,
		SortByPipe,
		SanitizerPipe,
		FilterServicesPipe,
        FilterMenuServicesPipe,
		CounterCustomerPipe,
		MatchDisabledPipe,
	],
})
export class PipesModule {}
